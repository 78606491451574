export const useAllCardsTogos = (
    gameTogo: Record<number, number>
): number[] => {
    const result: number[] = [];

    for (let i = 1; i <= 4; i++) {
        result.push(gameTogo[i] || 0);
    }

    return result;
};
