import { FC, HTMLAttributes } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_MOBILE } from '../ChatHeader/mobile';
import { CHAT_HEADER_HEIGHT_REM as CHAT_HEADER_HEIGHT_REM_DESKTOP } from '../ChatHeader/desktop';
import { FOOTER_HEIGHT_REM as FOOTERSG_HEIGHT_REM } from '../FooterSG';
import { FOOTER_HEIGHT_REM } from '../Footer';

const PADDING_TOP = isMobile
    ? CHAT_HEADER_HEIGHT_REM_MOBILE
    : CHAT_HEADER_HEIGHT_REM_DESKTOP;

type Rooms = {
    id: number;
    name: string;
};

type ChatRoomsProps = HTMLAttributes<HTMLDivElement> & {
    size?: number;
    currentRoom: string;
    rooms: Rooms[];
    onJoin: (roomId: number) => void;
};

const ChatRoomsContainer = styled.div<{ size?: number }>`
    border-radius: 1rem;
    z-index: 10;
    padding: ${PADDING_TOP}rem 0 ${FOOTER_HEIGHT_REM}rem;

    ${({ size }) => `
        ${
            isMobile
                ? `
                position: fixed;
                top: 16.5rem;
                bottom: 0;
                width: 46.875rem;
                max-width: 100%;
            `
                : `
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                
                top: ${size}rem;
        `
        }
    `}

    background-color: ${({ theme }) => theme.chatRooms.background.container};
`;

const CurrentRoom = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    ${isMobile
        ? `
            font-size: 2.25rem;
            height: 7.5rem;
            padding-left: 1.125rem;
        `
        : `
            font-size: 1.25rem;
            height: 3.5rem;
            padding-left: 0.5rem;
    `}

    ${({ theme }) => `
        color: ${theme.chatRooms.color.currentRoom};
        background-color: ${theme.chatRooms.background.currentRoom};
        border-left: 0.75rem solid ${theme.chatRooms.border.currentRoom};
    `};
`;

const CurrentRoomIcon = styled.div`
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    margin-right: 1.063rem;

    ${({ theme }) => `
        background-color: ${theme.chatRooms.background.icon};
        box-shadow: inset 0 0.063rem 0 0 ${theme.chatRooms.boxShadow.icon};
    `};
`;

const CurrentRoomName = styled.div`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    width: 40rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AvailableRooms = styled.div`
    height: 100%;

    ${isMobile
        ? `
            padding: 0.5rem 0.5rem ${
                FOOTER_HEIGHT_REM + FOOTERSG_HEIGHT_REM + 0.375
            }rem 0;
        `
        : `
            padding: 0.875rem 0.375rem ${FOOTER_HEIGHT_REM - 1.5}rem 0.75rem;
    `}

    background-color: ${({ theme }) => theme.chatRooms.background.container};
`;

const ScrollContainer = styled.div`
    height: 100%;
    overflow-x: auto;

    ${({ theme: { chatUsers } }) => `
        &::-webkit-scrollbar-track {
            border-radius: 0.75rem;
            background-color: ${chatUsers.scroll.track};
            border-left: 0.438rem solid ${chatUsers.border.scroll};
            border-right: 0.438rem solid ${chatUsers.border.scroll};
        }

        &::-webkit-scrollbar {
            width: 1.625rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.75rem;
            background-color: ${chatUsers.scroll.thumb};
        }
    `};
`;

const RoomItem = styled.div`
    font-family: Roboto-Bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${isMobile
        ? `
            font-size: 2.25rem;
            height: 10.438rem;
            padding: 2.438rem 2.313rem;
        `
        : `
            font-size: 1.25rem;
            height: 4.5rem;
            padding: 1.438rem 1.313rem;
    `}

    border-bottom: 0.188rem solid
        ${({ theme }) => theme.chatRooms.border.roomItem};
`;

const RoomName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 28rem;
`;

const JoinRoomButton = styled.button`
    font-family: Signika-Medium;
    border-radius: 0.969rem;
    cursor: pointer;

    ${isMobile
        ? `
            font-size: 2rem;
            width: 12.125rem;
            height: 4.375rem;
        `
        : `
            font-size: 1.25rem;
            width: 11.125rem;
            height: 3.125rem;
    `}

    ${({ theme }) => `
        color: ${theme.chatRooms.color.button};
        border: solid 0.094rem ${theme.chatRooms.border.button};
        box-shadow: inset 0 0.25rem 0 0 ${theme.chatRooms.boxShadow.button.first},
            inset 0 -0.25rem 0 0 ${theme.chatRooms.boxShadow.button.second};
        background-image: linear-gradient(to bottom, ${theme.chatRooms.backgroundGradient.upper}, ${theme.chatRooms.backgroundGradient.lower} 99%);
    `};
`;

export const ChatRooms: FC<ChatRoomsProps> = ({
    size,
    currentRoom,
    rooms,
    onJoin,
    ...props
}) => {
    const { t } = useTranslation();

    const $onJoin = useSoundHandler(
        (roomId: number) => onJoin(roomId),
        'click'
    );

    return (
        <ChatRoomsContainer {...props} size={size}>
            <CurrentRoom>
                <CurrentRoomIcon />
                <CurrentRoomName>{currentRoom}</CurrentRoomName>
            </CurrentRoom>
            <AvailableRooms>
                <ScrollContainer>
                    {rooms.map((room) => {
                        return (
                            <RoomItem key={room.id}>
                                <RoomName>{room.name}</RoomName>
                                <JoinRoomButton
                                    onClick={() => $onJoin(room.id)}
                                >
                                    {t('chatModal.joinRoom')}
                                </JoinRoomButton>
                            </RoomItem>
                        );
                    })}
                </ScrollContainer>
            </AvailableRooms>
        </ChatRoomsContainer>
    );
};
