import { FC, useEffect, useState, useCallback } from 'react';
import { useHookstate } from '@hookstate/core';
import { WinnerModal as WinnerModalComponent } from '../../components/WinnerModal';
import { useFormatCurrency } from '../../hooks/use-format-currency';
import { getQuery } from '../../lib/query';
import {
    meta,
    current,
    recentWinners,
    getRecentWinners,
} from '../../stores/backend';

export const RecentWinnersModal: FC = () => {
    const [index, setIndex] = useState<number>(0);
    const { ROOMID, SESSIONID } = getQuery();

    const $recentWinners = useHookstate(recentWinners);
    const $meta = useHookstate(meta);
    const $current = useHookstate(current);

    // make sure winners with empty parts and empty part winners are not displayed
    const _recentWinners = $recentWinners.filter((winners) => {
        if (winners.gameContinued.value) {
            return (
                winners.parts.length > 0 &&
                winners.parts.every((part) => part.extraWinners.length > 0)
            );
        } else {
            return (
                winners.parts.length > 0 &&
                winners.parts.every((part) => part.winners.length > 0)
            );
        }
    });

    const formatCurrency = useFormatCurrency($current, $meta);

    const WINNER_INDEX = {
        min: 0,
        max: _recentWinners.length - 1,
    };

    const winners = _recentWinners[index];
    const parts = winners.parts;

    const partWinners = parts.map((part) => {
        if (winners.gameContinued.value) {
            return part.extraWinners.map((winner) => {
                return {
                    name: winner.alias.value,
                    prize: formatCurrency(
                        winner.points.value,
                        winners.currency.value
                    ),
                };
            });
        } else {
            return part.winners.map((winner) => {
                return {
                    name: winner.alias.value,
                    prize: formatCurrency(
                        winner.prize.value,
                        winners.currency.value
                    ),
                };
            });
        }
    });

    const partConsolationWinners = parts
        .filter((part) => part.consolationWinners.value)
        .map((part) => {
            return part.consolationWinners.value?.map((consolationWinner) => {
                return {
                    userAlias: consolationWinner.alias,
                    amount: consolationWinner.prize,
                    isBonus: false,
                };
            });
        });

    const gameName = parts[parts.length - 1].name.value;
    const winningCall = parts[parts.length - 1].winners[0]?.call;
    const winningNumber = parts[parts.length - 1].winners[0]?.callNumber;

    const onPrevious = () => {
        if (index !== WINNER_INDEX.min) {
            setIndex(index - 1);
        }
    };

    const onNext = () => {
        if (index !== WINNER_INDEX.max) {
            setIndex(index + 1);
        }
    };

    const onClose = useCallback(() => {
        $meta.modals.recWinners.set(false);
        $meta.loaded.recWinners.set(false);
    }, [$meta.modals.recWinners, $meta.loaded.recWinners]);

    useEffect(() => {
        if ($meta.modals.recWinners.value) {
            getRecentWinners(parseInt(ROOMID), SESSIONID);
        }
    }, [$meta.modals.recWinners.value, ROOMID, SESSIONID]);

    return (
        <WinnerModalComponent
            isOpen={$meta.modals.recWinners.value}
            loading={$meta.loaders.recWinners.value}
            loaded={$meta.loaded.recWinners.value}
            gameName={gameName}
            gameId={winners.gameSessionId.value}
            winningCall={winningCall?.value}
            partWinners={partWinners}
            winningNumber={winningNumber?.value}
            recentWinners={true}
            gameContinued={winners.gameContinued.value}
            totalPrizes={formatCurrency(
                winners.totalPrize.value,
                winners.currency.value
            )}
            consolationWinners={partConsolationWinners[0] || []}
            onPrevious={onPrevious}
            onNext={onNext}
            onClose={onClose}
        />
    );
};
