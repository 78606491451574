import { current, calls, nextCalls, gameToGo } from '../../stores/backend';
import { BackendSocket } from '../../hooks/use-socket';
import { BingoCallMessage } from '../../types/bingo-events/in/backend/call-message';

export const onCallMessage = (socket: BackendSocket) => {
    const callback = (data: BingoCallMessage) => {
        calls.set(data.callNumbers);
        nextCalls.set(data.nextCallballs);
        gameToGo.set(data.gameToGo || {});
        current.merge({
            current_part: data.part - 1,
        });
    };

    socket.on('bingo:callMessage', callback);

    return () => {
        socket.off('bingo:callMessage', callback);
    };
};
