import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { useHookstate } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { ToGoPanel } from '../../components/ToGoPanel';
import {
    SettingsMenuButton,
    SettingsMenuButtonText,
} from '../../components/SettingsMenuButton';
import { useTogoMarkers } from '../../hooks/use-togo-markers';
import { useBoughtTickets } from '../../hooks/use-bought-tickets';
import { useAllCardsTogos } from '../../hooks/use-all-cards-togos';
import { calls, current, meta, gameToGo } from '../../stores/backend';
import { chatMeta } from '../../stores/chat';

const buttonStyles = css`
    width: ${isMobile ? '7.5rem' : '11.375rem'};
    height: 2.75rem;
    margin: 0;

    ${SettingsMenuButtonText} {
        font-size: 1.125rem;
    }
`;

const TogoContainer = styled.div<{ chatOpen: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.375rem 0.25rem;
    border-radius: 1rem;

    ${({ chatOpen, theme: { togoPanelContainer } }) => `
        width: ${isMobile ? '100%' : '59.625rem'} ;
        margin-left: ${isMobile ? 0 : '1.25rem'} ;
        margin-bottom: ${isMobile ? (chatOpen ? 0 : '1.1875rem') : '0.5rem'} ;
        background-color: ${togoPanelContainer.background}
         
    `}
`;

const MyCardsButton = styled(SettingsMenuButton)`
    ${buttonStyles}
`;

const AllCardsButton = styled(SettingsMenuButton)`
    ${buttonStyles}
`;

export const Togo: FC = () => {
    const { t } = useTranslation();

    const $calls = useHookstate(calls);
    const $current = useHookstate(current);
    const $meta = useHookstate(meta);
    const $chatMeta = useHookstate(chatMeta);
    const $gameToGo = useHookstate(gameToGo);

    const [cardSource, setCardSource] = useState<'myCards' | 'allCards'>(
        'myCards'
    );

    const boughtTickets = useBoughtTickets($current);
    const myCardTogos = useTogoMarkers($current, $calls, $meta);
    const allCardsTogos = useAllCardsTogos($gameToGo.value);
    const withGameTogo = Object.keys($gameToGo).length > 0;

    if (boughtTickets === 0 || $current.game_mode.value !== 'GAME_MODE') {
        return null;
    }

    const onMyCards = () => {
        setCardSource('myCards');
    };

    const onAllCards = () => {
        setCardSource('allCards');
    };

    // TODO: hide buttons when there is no $gameToGo.value

    return (
        <TogoContainer chatOpen={$chatMeta.chatToggle.value}>
            <MyCardsButton
                active={cardSource === 'myCards'}
                disabled={!withGameTogo}
                onClick={onMyCards}
            >
                {t('cardDeck.myCards')}
            </MyCardsButton>
            {(cardSource === 'myCards' ? myCardTogos : allCardsTogos).map(
                (togo, index) => {
                    const LABEL = {
                        1: t('cardDeck.OneToGo'),
                        2: t('cardDeck.TwoToGo'),
                        3: t('cardDeck.ThreeToGo'),
                        4: t('cardDeck.FourOrMoreToGo'),
                    };

                    return (
                        <ToGoPanel
                            key={index}
                            label={LABEL[(index + 1) as keyof typeof LABEL]}
                            value={togo}
                        />
                    );
                }
            )}{' '}
            <AllCardsButton
                active={cardSource === 'allCards'}
                disabled={!withGameTogo}
                onClick={onAllCards}
            >
                {t('cardDeck.allCards')}
            </AllCardsButton>
        </TogoContainer>
    );
};
