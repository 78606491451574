import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useSoundHandler } from '../../hooks/use-sound-handler';
import { ChatHeaderProps } from './mobile';

export const CHAT_HEADER_HEIGHT_REM = 4.5;

const fontStyleCommon = css`
    font-family: Signika-SemiBold;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: ${({ theme }) => theme.color};
`;

const contentContainerCommon = css`
    ${({ theme: { chatHeader } }) => `
        box-shadow: inset 0 -0.25rem 0 0 ${chatHeader.boxShadow.content};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.content.upper}, ${chatHeader.backgroundGradient.content.lower} 99%);
    `};
`;

const ChatHeaderContainer = styled.div<{ size: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
    z-index: 10;

    height: ${CHAT_HEADER_HEIGHT_REM}rem;

    ${({ size, theme: { chatHeader } }) => `
        top: ${size}rem;
        box-shadow: inset 0 0.188rem 0 0 ${chatHeader.boxShadow.container};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.container.upper}, ${chatHeader.backgroundGradient.container.lower} 99%);
    `};
`;

const CloseButton = styled.div`
    font-family: Signika-Bold;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 4rem;
    height: 4.5rem;
    cursor: pointer;

    ${({ theme: { chatHeader } }) => `
        box-shadow: inset 0 0.188rem 0 0 ${chatHeader.boxShadow.close};
        background-image: linear-gradient(to bottom, ${chatHeader.backgroundGradient.content.upper}, ${chatHeader.backgroundGradient.content.lower} 99%);
    `};
`;

const Chatroom = styled.img`
    position: absolute;
    right: 0.625rem;
    top: 0.325rem;
    width: 2.125rem;
    height: 2.125rem;
    margin-right: 0.5rem;
`;

const ArrowDown = styled.img<{ size?: number }>`
    width: 2rem;
    height: 1%.5;
    position: absolute;
    left: 0.5rem;
    top: 1.5rem;
    cursor: pointer;

    ${({ size }) => (size === 0 ? '' : 'transform: rotate(180deg);')}
`;

const RoomNameContainer = styled.div`
    flex: 1;
    font-size: 1.25rem;
    height: 3.25rem;
    border-radius: 0.938rem;
    padding: 0.75rem 3.75rem 0.75rem 0.75rem;
    margin-left: 3rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    border: solid 0.125rem ${({ theme }) => theme.chatHeader.border};

    ${fontStyleCommon};
    ${contentContainerCommon};
`;

const UsersContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    width: 4.5rem;
    height: 3.25rem;
    margin: 0 0.5rem;
    border-radius: 0.938rem;
    cursor: pointer;

    border: solid 0.125rem ${({ theme }) => theme.chatHeader.border};

    ${fontStyleCommon};
    ${contentContainerCommon};
`;

const UsersIcon = styled.img`
    width: 1.5rem;
    height: 1.625rem;
    margin-right: 0.375rem;
`;

export const ChatHeaderDesktop: FC<ChatHeaderProps> = ({
    size,
    roomName,
    users,
    onChatroom,
    onUsers,
    onClose,
    handleToggle,
    ...props
}) => {
    const theme = useTheme();
    const { arrowDown, chatroom, users: usersIcon } = theme.images;

    const $onChatroom = useSoundHandler(onChatroom, 'click');
    const $onUsers = useSoundHandler(onUsers, 'click');
    const $onClose = useSoundHandler(onClose, 'click');

    return (
        <ChatHeaderContainer {...props} size={size}>
            <ArrowDown
                src={arrowDown}
                alt="Arrow Down Image"
                size={size}
                onClick={() => handleToggle!()}
            />
            <RoomNameContainer onClick={$onChatroom}>
                {roomName}
                <Chatroom src={chatroom} alt="Chatroom Image" />
            </RoomNameContainer>
            <UsersContainer onClick={$onUsers}>
                <UsersIcon src={usersIcon} alt="Users Icon" />
                {users}
            </UsersContainer>
            <CloseButton
                onClick={() => {
                    handleToggle!(1);
                    $onClose();
                }}
            >
                x
            </CloseButton>
        </ChatHeaderContainer>
    );
};
