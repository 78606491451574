import { FC, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CommonPanel } from '../CommonPanel';
import { InfoButton } from '../InfoButton';
import { PlayersCards } from '../PlayersCards';
import { PrimaryPanelText } from '../PrimaryPanelText';
import { GamePart, GamePartProps } from '../GamePart';
import { GameType, GameTypeProps } from '../GameType';
import { BingoCardPattern, BingoCardPatternProps } from '../BingoCardPattern';
import { BuyButton } from '../BuyButton';
import { CartImage, BuyButtonText } from '../BuyButton/mobile';
import { CountdownTimer } from '../CountdownTimer';

export type GameInfoProps = BingoCardPatternProps & {
    to: Date;
    seconds: number;
    gameTitle: string;
    patternName: string;
    gamePrize: string;
    gamePlayers: number | string;
    gameCards: number | string;
    hasJackpot: boolean;
    jackpotName?: string;
    jackpotCall?: number;
    jackpotPrize?: string;
    chatOpen: boolean;
    gameLinked?: boolean;
    gameContinued?: boolean;
    showTotalTickets: boolean;
    onInfoBox: () => void;
    onBuy: () => void;
};

export type NextGamePanelProps = HTMLAttributes<HTMLDivElement> &
    GameTypeProps &
    GamePartProps &
    GameInfoProps;

const NextGamePanelContainer = styled(CommonPanel)`
    height: auto;
    margin-bottom: 1rem;
`;

export const NextGamePart = styled(GamePart)<GameTypeProps>`
    margin-bottom: 0.625rem;

    ${({ gameType }) => `
        ${
            gameType !== 90
                ? `
                margin-right: 0.688rem;
            `
                : null
        }
    `};
`;

const UpperContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const UpperTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 65%;
`;

const UpperImageContainer = styled.div<GameTypeProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: ${({ gameType }) => (gameType === 90 ? '26%' : '35%')};
`;

const PartPatternContainer = styled.div<GameTypeProps>`
    display: flex;
    justify-content: flex-end;

    ${({ gameType }) => `
        align-items: ${gameType === 90 ? 'flex-end' : 'center'};
        flex-basis: ${gameType === 90 ? '60%' : '68%'};
        flex-direction: ${gameType === 90 ? 'column' : 'row'};
    `};
`;

const Divider = styled.hr`
    width: 91%;
    margin-left: 0;
    border-width: 0;
    height: 0.125rem;
    background-color: ${({ theme: { primaryPanel } }) =>
        primaryPanel.color.divider};
`;

const LowerContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.25rem;
`;

const LowerTextContainer = styled.div<{ showTotalTickets: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: ${({ showTotalTickets }) => (showTotalTickets ? '55%' : '75%')};
`;

const LowerImageContainer = styled.div<{ showTotalTickets: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: ${({ showTotalTickets }) => (showTotalTickets ? '45%' : '25%')};
`;

export const BuyButtonWrapper = styled(BuyButton)`
    width: 7.375rem;
    height: 3rem;
    border-radius: 0.625rem;

    ${BuyButtonText} {
        font-size: 1.75rem;
        margin-top: 0;
    }

    ${CartImage} {
        width: 1.875rem;
        height: 2rem;
    }
`;

export const CountdownTimerWrapper = styled(CountdownTimer)`
    font-size: 3rem;
    line-height: 3rem;
    margin-top: -0.75rem;
`;

export const GameContinuedIcon = styled.img`
    width: 2rem;
    height: 2rem;
`;

export const NextGamePanelMobile: FC<NextGamePanelProps> = ({
    to,
    gameTitle,
    patternName,
    gamePrize,
    gamePlayers,
    gameCards,
    hasJackpot,
    jackpotName,
    jackpotCall,
    jackpotPrize,
    currentPart,
    gameParts,
    gameType,
    patternType,
    parts,
    gameLinked,
    gameContinued,
    chatOpen,
    showTotalTickets,
    onInfoBox,
    onBuy,
    ...props
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { starImage4 } = theme.images;
    const NEXTGAME_GAME = t('infoBox.nextGame');

    return (
        <NextGamePanelContainer {...props} type="primary">
            <UpperContainer>
                <UpperTextContainer>
                    <PrimaryPanelText
                        label={NEXTGAME_GAME}
                        value={gameTitle}
                        type="big"
                        gameLinked={gameLinked}
                    />
                    <Divider />
                </UpperTextContainer>
                <UpperImageContainer gameType={gameType}>
                    <PartPatternContainer gameType={gameType}>
                        {gameContinued ? (
                            <GameContinuedIcon
                                src={starImage4}
                                alt="Continued Game Icon"
                            />
                        ) : (
                            <NextGamePart
                                gameParts={gameParts}
                                currentPart={currentPart}
                                gameType={gameType}
                            />
                        )}

                        {showTotalTickets && (
                            <BingoCardPattern
                                patternType={patternType}
                                parts={parts}
                                currentPart={currentPart - 1}
                                gameType={gameType}
                            />
                        )}
                    </PartPatternContainer>
                    <InfoButton handleClick={onInfoBox} />
                </UpperImageContainer>
            </UpperContainer>
            <LowerContainer>
                <LowerTextContainer showTotalTickets={showTotalTickets}>
                    {chatOpen && <CountdownTimerWrapper to={to} />}
                    <PrimaryPanelText
                        label={patternName}
                        value={gamePrize}
                        type="small"
                    />
                    {hasJackpot && (
                        <PrimaryPanelText
                            label={t('infoBox.jackpotFormatting', {
                                jpName: jackpotName,
                                jpCall: jackpotCall,
                            })}
                            value={jackpotPrize || ''}
                            type="small"
                        />
                    )}
                </LowerTextContainer>
                <LowerImageContainer showTotalTickets={showTotalTickets}>
                    <GameType gameType={gameType} />
                    {showTotalTickets ? (
                        <>
                            <PlayersCards type="players" text={gamePlayers} />
                            <PlayersCards type="cards" text={gameCards} />
                        </>
                    ) : (
                        <BingoCardPattern
                            patternType={patternType}
                            parts={parts}
                            currentPart={currentPart - 1}
                            gameType={gameType}
                        />
                    )}
                </LowerImageContainer>
            </LowerContainer>
        </NextGamePanelContainer>
    );
};
