import * as Sentry from '@sentry/react';
import { screenConfigPromise } from './lib/screen-config';

export const sentryInit = async () => {
    const {
        env,
        screenConfig: {
            site: {
                data: { sentry },
            },
        },
    } = await screenConfigPromise;

    if (sentry.trace === 'true') {
        Sentry.init({
            dsn: sentry.dsn,
            environment: env,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 1.0,
            // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
};
