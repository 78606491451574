import delay from 'delay';
import { BackendSocket } from '../../hooks/use-socket';
import {
    current,
    meta,
    next,
    resetCalls,
    resetNextCalls,
    resetGameToGo,
    pickedCards,
} from '../../stores/backend';
import { BingoGameModeChange } from '../../types/bingo-events/in/backend/game-mode-change';
import { BackendPayload } from './payloads';

export const onGameModeChange = (
    socket: BackendSocket,
    payload: BackendPayload
) => {
    const callback = async ({ data }: BingoGameModeChange) => {
        switch (data.status) {
            case 'GAME_OVER':
                current.merge({
                    player_cards: {},
                    game_mode: data.status,
                });
                resetCalls();
                resetNextCalls();
                resetGameToGo();
                break;

            case 'GAME_MODE':
                resetCalls();
                resetNextCalls();
                resetGameToGo();
                current.set(
                    JSON.parse(
                        JSON.stringify({
                            ...next.value,
                            game_mode: data.status,
                            current_part: 0,
                        })
                    )
                );
                payload.loadGame(true);
                await delay(1000);
                payload.getPickedCards();
                pickedCards.selected.set([]);
                break;

            case 'GAME_CANCELLED':
                meta.modals.gameCancelled.set(true);
                current.game_mode.set(data.status);
                break;
        }
    };

    socket.on('bingo:gameModeChange', callback);

    return () => {
        socket.off('bingo:gameModeChange', callback);
    };
};
